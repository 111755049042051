import VideoChat from "catalyst-vc-react";
import React from "react";
import "./App.css";
function App() {
	return (
		<VideoChat
			sessionKey={window.location.pathname.substring(1)}
			uniqueAppId="277c24c1-341c-4f82-9445-0f0adb3963e8"
			themeColor="#ff7542"
			onEndCall={() => (window.location.href = "https://cooperhorn.com/")}
		/>
	);
}

export default App;
